<template>
    <div class="main-container">
        <md-dialog :md-active.sync="opened" class="dialog-root">
            <md-dialog-title>
                Unterschrift anlegen oder aktualisieren
            </md-dialog-title>
            <md-dialog-content class="content-container">
                <div class="signature-pad-container">
                    <VueSignaturePad class="signature-pad" height="200px" width="1000px" ref="signature" :options="options"></VueSignaturePad>
                </div>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-raised responsive-button" @click="undo()">Rückgängig</md-button>
                <md-button class="md-raised md-accent responsive-button" @click="opened = false">Verwerfen und Schließen</md-button>
                <md-button class="md-raised md-primary responsive-button" @click="save()">Unterschrift aktualisieren</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
    import store from "../../store/store";
    import ToasterService from "../../service/toaster-service";

    export default {
        name: "Signature",
        data() {
            return {
                opened: false,
                options: {
                    penColor: "black"
                }
            }
        },
        methods: {
            save() {
                const canvas = this.$refs.signature.saveSignature('image/png').data;
                fetch(canvas)
                    .then(fetchedData => fetchedData.blob())
                    .then(blob => {
                        try {
                            store.dispatch('saveSignature', blob);
                            this.$toast.success(ToasterService.messages.signature_update_success, ToasterService.toastBaseOptions);
                        } catch {
                            this.$toast.error(ToasterService.messages.signature_update_failed, ToasterService.toastBaseOptions);
                        }
                });
                this.opened = false;
            },
            undo() {
                this.$refs.signature.undoSignature();
            },
        }
    };
</script>

<style scoped lang="scss">
    .signature-pad {
        cursor: crosshair;
        border-bottom: 1px solid darkgray;
    }

    .responsive-button {

        @media screen and(max-width: $screen--medium) {
            font-size: 10px;
        }
    }
</style>
