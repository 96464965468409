<template>
  <div id="app">
    <Profile class="main" />
  </div>
</template>

<script>
import Profile from './components/Profile';
import { mapActions } from 'vuex';
import store from "./store/store";

export default {
  name: 'App',
  data() {
    return {
      chosenUser: null
    }
  },
  components: {
    Profile
  },
  methods: {
    ...mapActions(['setChosenUser', 'setHostURL']),
    determineURL() {
      const link = window.location.href;
      const url = new URL(link);
      return url.searchParams.get('host');
    },
    determineUser() {
      const location = window.location.href;
      const url = new URL(location);
      this.chosenUser = url.searchParams.get('user');
    }
  },
  created() {
    this.determineUser();
    store.dispatch('setHostURL', this.determineURL());
    store.dispatch('setChosenUser', this.chosenUser);
  }
}
</script>

<style lang="scss">

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 0;
    background-color: white;
  }

  .main {
    height: 100%;
  }

  body, html, #app {
    margin: 0;
    height: 100%;
  }

  .section-title {
    font-size: 22px;
    border-bottom: 1px solid black;
    max-width: 500px;
    font-weight: 100;

    &--small {
      font-size: 18px;
      color: $pq-main;
    }

    &--no-border {
      font-size: 20px;
      font-weight: 100;
    }
  }

  .form-field {
    max-width: 615px;
    display: block;

    &--small {
      max-width: 300px;
      display: inline-block;
      margin-right: 15px;
    }
  }

  .md-tabs-container {
    max-height: calc(100% - 48px);
  }

  .md-dialog-container {
    padding: 30px;
  }

  .md-tab, .tab {
    min-height: 500px;
    overflow-y: scroll;
    max-height: calc(100vh - 58px);

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $screen--medium) {
      max-height: unset;
      overflow-y: hidden;
    }
  }

  .md-tabs-navigation .md-button {
    @media (max-width: $screen--small) {
      font-size: 11.5px !important;
    }

    @media(max-width: $screen--very-small) {
      font-size: 8.5px !important;
    }
  }

  .button {
    margin: 0 !important;
  }

  .md-dialog-container {
      &::-webkit-scrollbar {
          visibility: hidden;
      }
      @media(max-height: $screen-height--medium) {
          overflow-y: scroll!important;

          &::-webkit-scrollbar {
              width: 8px;
          }
          &::-webkit-scrollbar-thumb {
              background: $pq-main;
          }
      }
  }

  .signature-pad {
    border: 1px solid darkgray;
  }

</style>
