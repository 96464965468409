<template>
    <div class="form-container">
        <md-tabs md-active-tab="personalDataTab" class="tabs-container" :md-dynamic-height="dynamic_height" ref="tabs">
            <md-tab id="personalDataTab" class="tab" md-label="Persönliche Daten" v-on:click="handleRendering('personalData')">
                <personal-data v-if="renderOptions.personalData" />
            </md-tab>
            <md-tab id="addressTab" class="tab" md-label="Adressen" v-on:click="handleRendering('address')">
                <addresses v-if="renderOptions.address" />
            </md-tab>
            <md-tab id="signatureTab" class="tab" md-label="Unterschrift" v-if="isOwnUserProfile" v-on:click="handleRendering('signature')">
                <signature-editor v-if="renderOptions.signature" />
            </md-tab>
            <md-tab id="settingsTab" class="tab" md-label="Konto" v-if="isOwnUserProfile" v-on:click="handleRendering('settings')">
              <user-settings v-if="renderOptions.settings" />
            </md-tab>
        </md-tabs>
    </div>
</template>

<script>
import PersonalData from "../tabs/PersonalData";
import SignatureEditor from "../tabs/SignatureEditor";
import store from "../../store/store";
import { mapGetters } from "vuex";
import Addresses from "../tabs/Addresses";
import UserSettings from "../tabs/UserSettings";

export default {
    name: 'ProfileSettings',
    components: {UserSettings, Addresses, PersonalData, SignatureEditor },
    data() {
        return {
            swipeable: true,
            dynamic_height: true,
            isOwnUserProfile: false,
            renderOptions: {
                personalData: true,
                address: false,
                signature: false,
                settings: false
            }
        }
    },
    computed: {
        ...mapGetters(['getUserPermissions'])
    },
    methods: {
      handleRendering(option) {
          switch(option) {
              case 'personalData':
                  this.renderOptions.personalData = true;
                  this.renderOptions.address = false;
                  this.renderOptions.signature = false;
                  this.renderOptions.settings = false;
                  break;
              case 'address':
                  this.renderOptions.personalData = false;
                  this.renderOptions.address = true;
                  this.renderOptions.signature = false;
                  this.renderOptions.settings = false;
                  break;
              case 'signature':
                  this.renderOptions.personalData = false;
                  this.renderOptions.address = false;
                  this.renderOptions.signature = true;
                  this.renderOptions.settings = false;
                  break;
              case 'settings':
                  this.renderOptions.personalData = false;
                  this.renderOptions.address = false;
                  this.renderOptions.signature = false;
                  this.renderOptions.settings = true;
                  break;
          }
      }
    },
    async created() {
        await store.dispatch('checkForInitialization');
        this.isOwnUserProfile = this.getUserPermissions.isOwnUserProfile();
    }
}
</script>

<style scoped lang="scss">

    .form-container {
        flex-grow: 2;

        @media (max-width: $screen--medium) {
            width: 100%;
            flex-grow: 1;
        }
    }

    .tabs-container {
        background-color: white;
        height: 100%;
    }

    .title-container {
        text-align: center;
    }
</style>
