<template>
    <div class="main-container">
        <h1 class="section-title">Ihre Unterschrift </h1>
        <p v-if="getSignature() !== 'data:image/png;base64,'">Ihre aktuell gespeicherte Unterschrift</p>
        <p v-if="getSignature() === 'data:image/png;base64,'">Sie haben aktuell keine Unterschrift hinterlegt</p>
        <img :src="getSignature()" v-if="getSignature() !== 'data:image/png;base64,'" height="100px" class="signature-image">
        <p v-if="getSignature() !== 'data:image/png;base64,'">Erstellen oder überarbeiten Sie Ihre Unterschrift</p>
        <md-button class="md-raised md-primary" @click="toggle()">Unterschrift erstellen/erneuern</md-button>
        <md-button class="md-raised md-accent" @click="deleteSignature()" v-if="getSignature() !== 'data:image/png;base64,'">Unterschrift löschen</md-button>
        <div class="placeholder" v-if="getSignature() === 'data:image/png;base64,'"></div>
        <signature ref="signatureDialog"></signature>
    </div>
</template>

<script>
    import Signature from "../dialog/SignatureDialog";
    import {mapGetters} from "vuex";
    import store from "../../store/store";
    import toastrService from '../../service/toaster-service'

    export default {
        name: "SignatureEditor",
        components: {
            Signature
        },
        methods: {
          ...mapGetters(['getSignature']),
            toggle() {
                this.$refs.signatureDialog.opened = !this.$refs.signatureDialog.opened;
            },
            async deleteSignature() {
              try {
                  await store.dispatch('deleteSignature');
                  this.$toast.success(toastrService.messages.signature_deletion_success, toastrService.toastBaseOptions);
                  this.signatureURL = undefined;
              } catch(e) {
                  this.$toast.error(toastrService.messages.signature_deletion_failed, toastrService.toastBaseOptions);
              }
            },
        }
    }
</script>

<style scoped lang="scss">
    .content-container {
        border-top: 1px solid $pq-main;
    }
    .main-container {
        height: 100%;
    }
    .placeholder {
        min-height: 500px;
        overflow: hidden;
    }
    .signature-image {
        max-height: 100px;
    }
</style>
