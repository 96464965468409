import Vue from 'vue'
import App from './App.vue'
import VueMaterial from "vue-material"
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Vuelidate from "vuelidate/src";
import Vuex from "vuex";
import store from "./store/store";
import VueResource from 'vue-resource';
import Keycloak from 'keycloak-js';
import VueSignature from "vue-signature-pad";
import ENV_CONSTS from './environment/env';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueRx from "vue-rx";

import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd";

let initOptions = ENV_CONSTS.initOptions;
let keycloak = Keycloak(initOptions);

library.add(faUserSecret);

Vue.use(Vuex);
Vue.use(VueRx);
Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperPreview: true,
    clipperUpload: true
  }
});
Vue.use(Toast);
Vue.config.productionTip = false;
Vue.use(VueMaterial);
Vue.use(MenuIcon);
Vue.component('menu-icon', MenuIcon);
Vue.material.locale.dateFormat = 'dd.MM.yyyy';
Vue.use(Vuelidate);
Vue.use(VueResource);
Vue.use(VueSignature);

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload();
  }
  setInterval(() => {
    keycloak.updateToken(10).catch(function() {
      console.error("Wasn't able to refresh token");
    });
  });
  localStorage.setItem('token', keycloak.token);
  localStorage.setItem('parsed-token', keycloak.tokenParsed);
  new Vue({
    el: '#app',
    store,
    render: h => h(App, { props: {keycloak: keycloak}})
  })
});
