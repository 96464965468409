import Configuration from "./configuration";

export default {
    basePath: Configuration.value('VUE_APP_BASE_PATH'),

    trainee: 'TRAINEE',
    apprentice: 'APPRENTICESHIP',
    consultant: 'CONSULTANT',
    assistance: 'ASSISTANCE',
    ceo: 'CEO',

    GENDER_MALE: 'MALE',
    GENDER_FEMALE: 'FEMALE',
    GENDER_DIVERSE: 'DIVERSE',

    ROLE_ADMIN: 'admin',
    ROLE_USER: 'user',

    AddressType_HOME: 'HOME',
    AddressType_PROJECT: 'PROJEKT',

    initOptions: {
        url: Configuration.value('VUE_APP_KEYCLOAK_AUTH'),
        realm: Configuration.value('VUE_APP_KEYCLOAK_REALM'),
        clientId: Configuration.value('VUE_APP_KEYCLOAK_CLIENT'),
        onLoad: 'login-required'
    }
}
