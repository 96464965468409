<template>
    <div class="side-display-container" v-if="getProfile">
        <h1 class="section-title--no-border">Profilübersicht und Vorschau</h1>
        <div class="title-container">
            <div  class="menu-container">
                <md-menu md-direction="bottom-start" :mdCloseOnClick="closeOnClick">
                    <md-button md-menu-trigger class="picture-container" :style="pictureStyles"></md-button>
                    <md-menu-content>
                        <md-menu-item><md-button class="picture-option-button" @click="openDialog">Profilbild hochladen</md-button></md-menu-item>
                        <md-menu-item><md-button class="picture-option-button" @click="deleteAvatar">Profilbild löschen</md-button></md-menu-item>
                    </md-menu-content>
                </md-menu>
            </div>
            <div class="info-text">
                <h2>{{getProfile.firstName}} {{getProfile.lastName}}</h2>
                <p>{{getUserType}}</p>
            </div>
        </div>
        <div class="description-box">
            {{getProfile.description}}
        </div>
        <AvatarManagement ref="avatarDialog"></AvatarManagement>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AvatarManagement from "../dialog/AvatarUploadDialog";
    import store from "../../store/store";
    import ToasterService from "../../service/toaster-service";

    export default {
        name: 'ProfileOverview',
        components: { AvatarManagement },
        data() {
            return {
                closeOnClick: true
            }
        },
        computed: {
            ...mapGetters(['getAvatar', 'getProfile']),
            getUserType() {
                switch(this.getProfile.userType) {
                    case 0:
                        return "Praktikant";
                    case 1:
                        return "Auszubildender";
                    case 2:
                        return "Agent";
                    case 3:
                        return "Assistent";
                    case 4:
                        return "CEO";
                    default:
                        return "Es wurde kein Nutzertyp angegeben"
                }
            },
            pictureStyles() {
                return {
                    "background-image": 'url("' + this.getAvatar + '")',
                    "background-size": "cover",
                    'height': '100%'
                }
            },
        },
        methods: {
          openDialog() {
              this.$refs.avatarDialog.opened = true;
          },
          async deleteAvatar() {
              try {
                  await store.dispatch('deleteProfilePicture');
                  this.$toast.success(ToasterService.messages.avatar_deletion_success, ToasterService.toastBaseOptions);
              } catch {
                  this.$toast.error(ToasterService.messages.avatar_deletion_failed, ToasterService.toastBaseOptions);
              }
          }
        }
    }
</script>

<style scoped lang="scss">

    .side-display-container {
        max-width: 500px;
        min-width: 500px;
        flex-grow: .2;
        padding: 15px;
        color: white;
        background-color: $pq-main;

        @media (max-width: $screen--large) {
            max-width: 400px;
            min-width: 340px;
        }

        @media (max-width: $screen--medium) {
            max-width: 100%;
            min-width: 0;
            width: 100%;
            flex-grow: 1;
            margin: 0;
        }
    }

    .picture-container {
        border-radius: 5%;
        min-height: 100px;
        max-height: 100px;
        min-width: 100px;
        display: block;
        background-color: white;
        background-size: cover;
        height: 100%;
    }

    .title-container {
        display: flex;
    }

    .title-container .info-text {
        display: block;
        padding-left: 15px;
    }

    .description-box {
        margin-top: 15px;
    }

    .picture-option-button {
        padding: 0;
        margin: 0;
    }

</style>
