<template>
    <div class="main-content">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
        <md-dialog :md-active.sync="createDialogOpened" class="form-dialog">
            <md-dialog-title class="title section-title">Adresse hinzufügen</md-dialog-title>
            <form>
                <md-field class="form-field" :class="this.getValidationClass('country')">
                    <md-select v-model="address.country" required>
                        <md-option value="0">Deutschland</md-option>
                        <md-option value="1">Frankreich</md-option>
                        <md-option value="2">Österreich</md-option>
                        <md-option value="3">England</md-option>
                    </md-select>
                    <span class="md-error">Pflichtfeld</span>
                </md-field>
                <md-field class="form-field" id="postalCode" :class="this.getValidationClass('postcode')">
                    <label>Postleitzahl</label>
                    <md-input v-model="address.postcode" required></md-input>
                    <span class="md-error">Pflichtfeld</span>
                </md-field>
                <md-field class="form-field" id="city" :class="this.getValidationClass('city')">
                    <label>Stadt</label>
                    <md-input v-model="address.city" required></md-input>
                    <span class="md-error">Pflichtfeld</span>
                </md-field>
                <br>

                <div class="flex-wrapper">
                    <md-field class="flex-field--lg" id="street" :class="this.getValidationClass('street')">
                        <label>Straßenname</label>
                        <md-input v-model="address.street" required></md-input>
                        <span class="md-error">Pflchtfeld</span>
                    </md-field>
                    <md-field class="flex-field--sm" id="number" :class="this.getValidationClass('houseNumber')">
                        <label>Nr</label>
                        <md-input class="housenumber-input" v-model="address.houseNumber" required></md-input>
                        <span class="md-error">Pflichtfeld</span>
                    </md-field>
                </div>

                <md-field class="form-field" id="addressType" :class="this.getValidationClass('addressType')">
                    <label>Art der Adresse</label>
                    <md-select v-model="address.addressType" required>
                        <md-option value="0">Privat</md-option>
                        <md-option value="1">Projektwohnung</md-option>
                    </md-select>
                    <span class="md-error">Pflichtfeld</span>
                </md-field>
                <div class="flex-wrapper">
                    <md-button class="md-accent  standard-button--no-margin-left" @click="createDialogOpened = false">Abbrechen</md-button>
                    <md-button class="md-primary  standard-button--align-right" @click="validateData()">Speichern</md-button>
                </div>
            </form>
        </md-dialog>

        <md-dialog class="delete-dialog" :md-active.sync="deleteManagerObject.deleteDialogOpened">
            <md-dialog-title class="title section-title">Achtung!</md-dialog-title>
            <p>Sie sind gerade dabei, die folgende Adresse zu entfernen:</p>
            {{deleteManagerObject.toBeDeleted.addressType}} in {{showCountry(deleteManagerObject.toBeDeleted.country)}},<br>
            {{deleteManagerObject.toBeDeleted.street}} {{deleteManagerObject.toBeDeleted.houseNumber}},<br>
            {{deleteManagerObject.toBeDeleted.postcode}}, {{deleteManagerObject.toBeDeleted.city}}<br>
            <p>Sind Sie sich sicher, dass diese Adresse gelöscht werden soll?</p>
            <div class="flex-wrapper">
                <md-button class="md-primary standard-button--no-margin-left" @click="deleteManagerObject.deleteDialogOpened = false">Beibehalten</md-button>
                <md-button class="md-accent standard-button--align-right" @click="deleteAddress(deleteManagerObject.toBeDeleted)">Löschen</md-button>
            </div>
        </md-dialog>

        <div class="flex-wrapper">
            <p class="section-title flex-field--lg">Adressen</p>
        </div>
        <md-button class="md-primary" @click="openDialogue"><md-icon>add_box</md-icon> Adresse hinzufügen</md-button>
        <div class="address-list" v-if="addresses">
            <md-card v-for="item in addresses" :key="item.id" class="responsive-card">
                <md-card-header>
                    <h2>{{item.addressType}}</h2>
                </md-card-header>
                <md-card-content>
                    <p>{{item.street}} {{item.houseNumber}}, {{item.postcode}} {{item.city}}</p>
                    <p>{{showCountry(item.country)}}</p>
                </md-card-content>
                <md-card-actions>
                    <md-button class="md-accent responsive-delete-button" @click="openDeleteDialog(item)"><md-icon class="fa">delete</md-icon></md-button>
                </md-card-actions>
            </md-card>
        </div>
    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    import store from "../../store/store";
    import { mapGetters } from "vuex";
    import toast from "../../service/toaster-service";

    export default {
        name: "Addresses",
        data() {
            return {
                createDialogOpened: false,
                deleteManagerObject: {
                    toBeDeleted: {},
                    deleteDialogOpened: false
                },
                addresses: [],
                address: {
                    country: '',
                    street: '',
                    city: '',
                    postcode: '',
                    houseNumber: '',
                    addressType: 0
                }
            }
        },
        validations() {
            return {
                address: {
                    addressType: {
                        required
                    },
                    country: {
                        required
                    },
                    city: {
                        required
                    },
                    postcode: {
                        required
                    },
                    street: {
                        required
                    },
                    houseNumber: {
                        required
                    }
                }
            };
        },
        computed: {
            ...mapGetters(['getProfile'])
        },
        methods: {
            showCountry(countryCode) {
                switch (countryCode) {
                    case "GERMANY":
                        return "Deutschland";
                    case "FRANCE":
                        return "Frankreich";
                    case "AUSTRIA":
                        return "Österreich";
                    case "ENGLAND":
                        return "England";
                }
                return "Nicht Spezifiziert";
            },
            getValidationClass (fieldName) {
                const field = this.$v.address[fieldName];

                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },
            openDialogue() {
                this.createDialogOpened = true;
                this.clearFormValidation();
            },
            clearFormValidation() {
                this.$v.$reset();
                this.address = {
                    country: 0,
                    street: '',
                    city: '',
                    postcode: '',
                    houseNumber: '',
                    addressType: 0
                }
            },
            async validateData () {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    try {
                        await store.dispatch('updateAddresses', this.address);
                        this.$toast.success(toast.messages.address_update_success, toast.toastBaseOptions);
                    } catch {
                        this.$toast.error(toast.messages.address_update_failed, toast.toastBaseOptions)
                    }
                    this.createDialogOpened = false;
                    this.clearFormValidation();
                    this.addresses = this.getProfile.addresses;
                }
            },
            openDeleteDialog(address) {
                this.deleteManagerObject.toBeDeleted = address;
                this.deleteManagerObject.deleteDialogOpened = true;
            },
            deleteAddress(address) {
                try {
                    store.dispatch('deleteAddressFromUser', address);
                    this.$toast.success(toast.messages.address_deleted_success, toast.toastBaseOptions);
                } catch {
                    this.$toast.error(toast.messages.address_deletion_failed, toast.toastBaseOptions);
                }
                this.addresses = this.getProfile.addresses;
                this.deleteManagerObject.deleteDialogOpened = false;
            }
        },
        async mounted() {
            await store.dispatch('checkForInitialization');
            if (this.getProfile.addresses) {
                this.addresses = this.getProfile.addresses;
            } else {
                this.addresses = [];
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-content {
        overflow: auto;
        height: 100%;
    }

    .md-card {
        margin-top: 15px;
        max-width: 100%;
        border-bottom: 1px solid $pq-main;
    }

    .address-list {
        overflow: auto;
        max-width: 100%;
        display: flex;
    }

    .form-field {
        max-width: 615px;
        display: block;

        &--small {
            max-width: 300px;
            display: inline-block;
            margin-right: 15px;

            input {
                width: 100%;
            }
        }

        input {
            width: 100%;
        }
    }

    .form-dialog {
        .form-field {
            min-width: 600px;
        }
    }

    .responsive-card {
        max-width: 600px;
        min-width: 0;
        margin: 0 5px 0 5px;
    }

    .housenumber-input {
        width: 100%;
    }

    .flex-wrapper {
        width: 100%;
        display: flex;

        .flex-field--lg {
            width: 100%;
            @media (max-width: $screen--medium) {
                max-width: 100%;
            }
        }

        .flex-field--md {
            margin-top: 15px;
            margin-left: auto;
            @media (max-width: $screen--medium) {
                min-width: 200px;
                max-width: 100%;
            }
        }

        .flex-field--sm {
            max-width: 115px;
        }
    }

    .title {
        padding-left: 0;
    }

    .standard-button--no-margin-left {
        margin-left: 0;
    }

    .standard-button--align-right {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    .delete-dialog {
        .title {
            color: $negative;
        }

        .section-title {
            border-bottom: 1px solid $negative;
            max-width: 66%;
        }
    }

    .responsive-delete-button {
        @media (max-width: $screen--medium) {
            width: 100%;
        }
    }
</style>
