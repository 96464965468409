<template>
    <div class="main-container" v-if="profile != null">
        <h1 class="section-title">Persönliche Daten bearbeiten</h1>
        <p class="section-title--small">Grundinformationen</p>
        <form>
            <md-field class="form-field--small" :class="getValidationClass('firstName')">
                <label>Vorname</label>
                <md-input name="firstName" required v-model="profile.firstName"></md-input>
            </md-field>
            <md-field class="form-field--small" :class="getValidationClass('lastName')">
                <label>Nachname</label>
                <md-input required v-model="profile.lastName"></md-input>
                <span class="md-error">Pflichtfeld</span>
            </md-field>
            <md-field class="form-field"  id="mail"  :class="getValidationClass('email')">
                <label>E-Mail</label>
                <md-input required v-model="profile.email" :disabled="!userTypePermissions.adminPermission"></md-input>
                <span class="md-error">Pflichtfeld</span>
            </md-field>
            <p class="disclaimer" v-if="!userTypePermissions.adminPermission">* Die hinterlegte E-Mail Adresse kann nur von einem Administrator geändert werden</p>
            <md-field class="form-field"  id="gender"  :class="getValidationClass('gender')">
                <p class="section-title--small">Geschlecht*</p>
                <md-select required v-model="profile.gender">
                    <md-option value="0">Männlich</md-option>
                    <md-option value="1">Weiblich</md-option>
                    <md-option value="2">Divers</md-option>
                </md-select>
                <span class="md-error">Pflichtfeld</span>
            </md-field>
            <p class="section-title--small">Beschreibung*</p>
            <md-field class="form-field"  id="description"  :class="getValidationClass('description')">
                <label>Profilbeschreibung</label>
                <md-textarea class="description-box" required v-model="profile.description"></md-textarea>
                <span class="md-error">Pflichtfeld</span>
            </md-field>
            <md-field class="form-field"  id="userType"  :class="getValidationClass('userType')">
                <p class="section-title--small">Nutzertyp*</p>
                <md-select required v-model="profile.userType" :disabled="!userTypePermissions.userTypeChangeable && !userTypePermissions.adminPermission">
                    <md-option value="0">Praktikant</md-option>
                    <md-option value="1">Auszubildender</md-option>
                    <md-option value="2">Consultant</md-option>
                    <md-option value="3">Assistenz</md-option>
                    <md-option value="4">CEO</md-option>
                </md-select>
                <span class="md-error">Pflichtfeld</span>
            </md-field>
            <p class="disclaimer" v-if="!userTypePermissions.userTypeChangeable && !userTypePermissions.adminPermission">* Sie können Ihren Nutzertyp nicht mehr ändern, sobald er initialisiert wurde</p>
            <md-field class="form-field" id="birthdate"  :class="getValidationClass('birthdate')" v-if="birthdateEditPermission">
                <p class="section-title--small">Geburtsdatum*</p>
                <md-datepicker dateFormat="dd/MM/yyyy" :option="dateOptions" required v-model="profile.birthdate" :md-immediately="true"></md-datepicker>
                <span class="md-error" v-if="!$v.profile.birthdate.required">Pflichtfeld</span>
                <span class="md-error" v-if="!$v.profile.birthdate.maxValue">Bitte geben Sie ein gültiges Datum an</span>
            </md-field>
            <div class="date-display" v-else>
                <p class="section-title--small">Geburtsdatum:</p>
                <p class="date-block">{{profile.birthdate.toLocaleString().split(',')[0]}}</p>
            </div>
            <md-field class="form-field" id="employed"  :class="getValidationClass('hiringDate')" v-if="hiringDateEditPermission">
                <p class="section-title--small">Eingestellt am*</p>
                <md-datepicker dateFormat="dd/MM/yyyy" :option="dateOptions" v-model="profile.hiringDate" required :md-immediately="true"/>
                <span class="md-error" v-if="!$v.profile.hiringDate.required">Pflichtfeld</span>
                <span class="md-error" v-if="!$v.profile.hiringDate.maxValue">Bitte geben Sie ein gültiges Datum an</span>
            </md-field>
            <div class="date-display" v-else>
                <p class="section-title--small">Eingestellt am:</p>
                <p class="date-block">{{profile.hiringDate.toLocaleString().split(',')[0]}}</p>
            </div>
            <p class="disclaimer" v-if="!birthdateEditPermission || !hiringDateEditPermission">* Wenden Sie sich im Falle eines fehlerhaften Datums bitte an einen Administrator</p>
            <md-field class="form-field"  id="apprenticeship" v-if="profile && profile.userType === userType.APPRENTICESHIP || profile.userType === userType.TRAINEE">
                <label>Jahr</label>
                <md-input name="year" type="number" required v-model="profile.year"></md-input>
                <span class="md-error" v-if="!$v.profile.year.required">Pflichtfeld</span>
                <span class="md-error" v-if="!$v.profile.year.maxValue">Bitte geben Sie einen gültigen Wert an (Maximal 3)</span>
            </md-field>
            <md-field class="form-field"  id="temp"  v-if="profile && profile.userType === userType.TRAINEE">
                <p class="section-title--small">Temporär*</p>
                <md-select required v-model="profile.temporary">
                    <md-option value="0">Ja</md-option>
                    <md-option value="1">Nein</md-option>
                </md-select>
                <span class="md-error">Pflichtfeld</span>
            </md-field>
            <br>
            <md-button class="md-primary md-raised md-primary md-theme-default button" @click="validateData()">Änderungen speichern</md-button>
        </form>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import store from "../../store/store";
    import UserType from "../../store/enum/UserType";
    import UserInfoResolver from '../../service/user-info-resolver';
    import toast from "../../service/toaster-service";

    export default {
        name: "PersonalData",
        data: () => {
            return {
                date: new Date().getDate(),
                dateOptions: {
                    disabled: {
                        from: new Date(),
                    }
                },
                userType: UserType,
                profile: {},
                birthdateEditPermission: true,
                hiringDateEditPermission: true,
                userTypePermissions: {
                    userTypeChangeable: false,
                    adminPermission: false
                }
            }
        },
        computed: {
            ...mapGetters(['getProfile', 'getUserPermissions', 'getFirstChange'])
        },
        validations() {
            return UserInfoResolver.resolveUserValidationOptions(this.profile);
        },
        methods: {
            ...mapActions(['updateProfileData']),
            clearFormValidation() {
                this.$v.$reset();
            },
            getValidationClass (fieldName) {
                const field = this.$v.profile[fieldName];
                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },
            async validateData () {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.clearFormValidation();
                    try {
                        await this.updateProfileData(this.profile);
                        await this.$toast.success(toast.messages.profile_update_success, toast.toastBaseOptions);
                        this.userTypePermissions.userTypeChangeable = this.getFirstChange;
                        this.birthdateEditPermission = this.getUserPermissions.birthdayPermission();
                        this.hiringDateEditPermission = this.getUserPermissions.hiringDatePermission();
                    } catch {
                        this.$toast.error(toast.messages.profile_update_failed, toast.toastBaseOptions);
                    }
                    this.profile = UserInfoResolver.mapUser(this.profile);
                }
            }
        },
        async mounted() {
            await store.dispatch('checkForInitialization');
            this.profile = this.getProfile;
            this.userTypePermissions.userTypeChangeable = this.getFirstChange;
            this.userTypePermissions.adminPermission = this.getUserPermissions.adminPermission();
            this.birthdateEditPermission = this.getUserPermissions.birthdayPermission();
            this.hiringDateEditPermission = this.getUserPermissions.hiringDatePermission();
        }
    }
</script>

<style scoped lang="scss">

    .main-container {
        padding: 5px;
        margin-bottom: 80px;
    }

    .form-field {
        max-width: 615px;
        display: block;

        &--small {
            max-width: 300px;
            display: inline-block;
            margin-right: 15px;

            input {
                width: 100%;
            }
        }

        input {
            width: 100%;
        }
    }

    .description-box {
        width: 100%;
    }

    .disclaimer {
        color: darkgray;
    }

    .date-display {
        font-size: 17px;
    }
</style>
