import GENDER from "../store/enum/Gender";
import UserType from "../store/enum/UserType";
import { ApprenticeshipApi, EmployeeApi, TraineeApi } from "@proQrent-clients/employee.service.axios.client";
import ENV_CONSTS from '../environment/env'
import { required, email, maxValue } from 'vuelidate/lib/validators';

export default {

    mapUser(user) {
        if (user.hiringDate !== null && user.birthdate !== null) {
            user.hiringDate = new Date(user.hiringDate);
            user.birthdate = new Date(user.birthdate);
        }
        switch (user.gender) {
            case ENV_CONSTS.GENDER_MALE:
                user.gender = GENDER.MALE;
                break;
            case ENV_CONSTS.GENDER_FEMALE:
                user.gender = GENDER.FEMALE;
                break;
            case ENV_CONSTS.GENDER_DIVERSE:
                user.gender = GENDER.DIVERSE;
                break;
        }
        switch (user.userType) {
            case ENV_CONSTS.trainee:
                user.userType = UserType.TRAINEE;
                break;
            case ENV_CONSTS.apprentice:
                user.userType = UserType.APPRENTICESHIP;
                break;
            case ENV_CONSTS.consultant:
                user.userType = UserType.CONSULTANT;
                break;
            case ENV_CONSTS.assistance:
                user.userType = UserType.ASSISTANCE;
                break;
            case ENV_CONSTS.ceo:
                user.userType = UserType.CEO;
                break;
        }
        user.addresses = this.mapAddressInformation(user.addresses, false);
        return user;
    },

    getUserApiType(tokenInfo) {
        const basePath = ENV_CONSTS.basePath;
        if (tokenInfo.resource_access['employee-service'].roles.includes("apprenticeship")) {
            return new ApprenticeshipApi({
                basePath: basePath
            });
        } else if (tokenInfo.resource_access['employee-service'].roles.includes("trainee")) {
            return new TraineeApi({
                basePath: basePath
            });
        } else {
            return new EmployeeApi({
                basePath: basePath
            });
        }
    },

    mapToDTO(user) {
        user.birthdate.setHours(12);
        user.hiringDate.setHours(12);
        switch(user.gender) {
            case GENDER.MALE:
                user.gender = ENV_CONSTS.GENDER_MALE;
                break;
            case GENDER.FEMALE:
                user.gender = ENV_CONSTS.GENDER_FEMALE;
                break;
            case GENDER.DIVERSE:
                user.gender = ENV_CONSTS.GENDER_DIVERSE;
                break;
        }
        switch(user.userType) {
            case UserType.TRAINEE:
                user.userType = ENV_CONSTS.trainee;
                break;
            case UserType.APPRENTICESHIP:
                user.userType = ENV_CONSTS.apprentice;
                break;
            case UserType.CONSULTANT:
                user.userType = ENV_CONSTS.consultant;
                break;
            case UserType.CEO:
                user.userType = ENV_CONSTS.ceo;
        }
        user.addresses = this.mapAddressInformation(user.addresses, true);
        return user;
    },

    headerConfig(token) {
        return {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    },
    arrayBufferHeader(token) {
        return {
            headers: {
                Authorization: 'Bearer ' + token,
                'content-type': 'image/png',
                'accept': 'image/png'
            },
            responseType: 'arraybuffer',
            responseEncoding: 'binary'
        }
    },

    mapAddressInformation(addresses, isTransferObject) {
        if (isTransferObject) {
            for (let item in addresses) {
                switch (item.addressType) {
                    case 0:
                        item.addressType = ENV_CONSTS.AddressType_HOME;
                        break;
                    case 1:
                        item.addressType = ENV_CONSTS.AddressType_PROJECT;
                        break;
                }
            }
        } else {
            for (let item in addresses) {
                switch (item.addressType) {
                    case ENV_CONSTS.AddressType_HOME:
                        item.addressType = 0;
                        break;
                    case ENV_CONSTS.AddressType_PROJECT:
                        item.addressType = 1;
                        break;
                }
            }
        }
        return addresses;
    },

    resolveUserValidationOptions(profile) {
        if(profile.userType === 1) {
            return {
                profile: {
                    firstName: {
                        required
                    },
                    lastName: {
                        required
                    },
                    email: {
                        required,
                        email
                    },
                    userType: {
                        required
                    },
                    description: {
                        required
                    },
                    birthdate: {
                        required,
                        maxValue: maxValue(new Date())
                    },
                    gender: {
                        required
                    },
                    hiringDate: {
                        required,
                        maxValue: maxValue(new Date())
                    },
                    year: {
                        required,
                        maxValue: 3
                    }
                }
            }
        } else if (profile.userType === 0) {
            return {
                profile: {
                    firstName: {
                        required
                    },
                    lastName: {
                        required
                    },
                    email: {
                        required,
                        email
                    },
                    userType: {
                        required
                    },
                    description: {
                        required
                    },
                    birthdate: {
                        required,
                        maxValue: maxValue(new Date())
                    },
                    gender: {
                        required
                    },
                    hiringDate: {
                        required,
                        maxValue: maxValue(new Date())
                    },
                    year: {
                        required,
                        maxValue: 3
                    },
                    temporary: {
                        required,
                    }
                }
            }
        } else {
            return {
                profile: {
                    firstName: {
                        required
                    },
                    lastName: {
                        required
                    },
                    email: {
                        required,
                        email
                    },
                    userType: {
                        required
                    },
                    description: {
                        required
                    },
                    birthdate: {
                        required,
                        maxValue: maxValue(new Date())
                    },
                    gender: {
                        required
                    },
                    hiringDate: {
                        required,
                        maxValue: maxValue(new Date())
                    }
                }
            }
        }
    },

    checkForFirstInitOfUserData(profile) {
        return profile.gender === null && profile.userType === null &&
            profile.hiringDate === null && profile.birthdate === null;
    }
}
