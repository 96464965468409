import {POSITION} from "vue-toastification";

export default {
    messages: {
        profile_update_success: "Ihr Profil wurde erfolgreich aktualisiert",
        profile_update_failed: "Beim Aktuaklisieren Ihres Profils ist ein Fehler aufgetreten",
        address_update_success: "Die Adresse wurde erfolgreich Ihrem Profil hinzugefügt",
        address_update_failed: "Die Adresse konnte Ihrem Profil nicht hinzugefügt werden",
        address_deleted_success: 'Die Adresse wurde von Ihrem Profil entfernt',
        address_deletion_failed: 'Die Addresse konnte nicht entfernt werden',
        signature_update_success: 'Ihre Unterschrift wurde Erfolgreich aktualisiert',
        signature_update_failed: 'Leider konnte Ihre Unterschrift nicht aktualisiert werden',
        avatar_update_success: 'Ihr Profilbild wurde erfolgreich aktualisiert',
        avatar_update_failed: 'Leider konnte ihr Profilbild nicht aktualisiert werden',
        avatar_deletion_success: "Ihr Profilbild wurde gelöscht",
        avatar_deletion_failed: "Ihr Profilbild konnte nicht gelöscht werden",
        signature_deletion_success: "Ihre Unterschrift wurde gelöscht",
        signature_deletion_failed: "Ihr Unterschrift konnte nicht gelöscht werden",
        password_update_success: "Ihr Passwort wurde erfolgreich aktualisiert",
        password_update_failed: "Ihr Passwort konnte nicht aktualisiert werden"
    },
    toastBaseOptions: {
        position: POSITION.BOTTOM_RIGHT,
            timeout: 2000
    }
}
