<template>
    <div class="main-container">
        <md-dialog :md-active.sync="opened">
            <md-dialog-title>Profilbild aktualisieren</md-dialog-title>
            <md-dialog-content class="dialog-content">
                <clipper-upload class="upload-control" v-model="imageURL"><md-button class="md-primary">Bild hochladen</md-button></clipper-upload>
                <div class="preview-display">
                    <clipper-basic :ratio="ratio" ref="clipper" :src="imageURL">
                        <div class="placeholder" slot="placeholder">Kein Bild ausgewählt</div>
                    </clipper-basic>
                </div>
                <md-button class="md-accent" @click="cloaseDialogAndResetPic">Abbrechen</md-button>
                <md-button v-if="imageURL" @click="cropAndSave" class="md-primary">Bild zuschneiden und speichern</md-button>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
    import "vuejs-clipper/dist/vuejs-clipper.css";
    import store from "../../store/store";
    import ToasterService from "../../service/toaster-service";

    export default {
        name: "AvatarManagement",
        data() {
            return {
                opened: false,
                imageURL: null,
                ratio: 1
            }
        },
        methods: {
            log() {
                console.log(this.imageURL);
            },
            cloaseDialogAndResetPic() {
                this.imageURL = null;
                this.opened = false;
            },
            cropAndSave() {
                const canvas = this.$refs.clipper.clip("image/png");
                canvas.toBlob(
                    async (result) => {
                        try {
                            await store.dispatch('uploadProfilePicture', result);
                            this.$toast.success(ToasterService.messages.avatar_update_success, ToasterService.toastBaseOptions);
                        } catch {
                            this.$toast.error(ToasterService.messages.avatar_update_failed, ToasterService.toastBaseOptions);
                        }
                    }
                );
                this.opened = false;
            }
        }
    }
</script>

<style scoped lang="scss">
    .preview-display {
        max-width: 300px;
    }

    .dialog-content::-webkit-scrollbar {
        display: none;
    }

    .upload-control {
        border-bottom: none;
    }
</style>
