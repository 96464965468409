import UserInfoResolver from '../../service/user-info-resolver';
import jwtDecode from "jwt-decode";
import {SignatureApi, AvatarApi, UserAdministrationApi} from "@proQrent-clients/employee.service.axios.client";
import ENV_CONSTS from '../../environment/env';
import PermissionService from '../../service/permission-grant-service';
import store from "../store";

export const state = {
    keycloak: undefined,
    userApi: undefined,
    profile: {},
    avatarApi: new AvatarApi({
        basePath: ENV_CONSTS.basePath
    }),
    passwordApi: new UserAdministrationApi({
        basePath: ENV_CONSTS.basePath
    }),
    requestUser: '',
    token: '',
    userPermissions: {},
    signatureApi: new SignatureApi({
        basePath: ENV_CONSTS.basePath
    }),
    initialized: false,
    signatureURL: '',
    avatarURL: '',
    firstChange: false,
    hostURL: ''
};

export const mutations = {
    setProfile: (state, data) => { state.profile = data; },
    setUserApi: (state, api) => {
        state.userApi = api;
    },
    setToken: (state, token) => {
        state.token = token;
    },
    setRequestedUser(state, data) {
        state.requestUser = data;
    },
    setUserPermissions(state, permissions) {
      state.userPermissions = permissions;
    },
    setInitializationConfirmation(state, confirmation) {
        state.initialized = confirmation;
    },
    addAddress(state, address) {
        if(state.profile.addresses) {
            state.profile.addresses.push(address);
        } else {
            state.profile.addresses = [address];
        }
    },
    deleteAddress(state, address) {
        state.profile.addresses.splice(state.profile.addresses.indexOf(address), 1);
    },
    setSignatureUrl(state, url) {
        state.signatureURL = url;
    },
    setAvatarUrl(state, url) {
        state.avatarURL = url;
    },
    setFirstChange(state, checkValue) {
        state.firstChange = checkValue;
    },
    setHostURL(state, url) {
        state.hostURL = url;
    }

};

export const actions = {
    async checkForInitialization() {
        if (!state.initialized) {
            await store.dispatch('initApp');
        }
    },
    setHostURL({commit}, url) {
        commit('setHostURL', url);
    },
    setChosenUser({commit}, chosenUser) {
        if (chosenUser) {
            commit('setRequestedUser', chosenUser)
        } else {
            commit('setRequestedUser', jwtDecode(localStorage.getItem('token')).preferred_username);
        }
    },
    async initApp({commit}) {
        commit('setToken', localStorage.getItem('token'));
        commit('setUserApi', UserInfoResolver.getUserApiType(jwtDecode(localStorage.getItem('token'))));
        await state.userApi.getUserProfileFromUserId(state.requestUser, UserInfoResolver.headerConfig(state.token)).then(
            returnData => {
                commit('setProfile', UserInfoResolver.mapUser(returnData.data));
                commit('setUserPermissions', PermissionService.editorPermissions(state.profile, jwtDecode(state.token)));
                commit('setInitializationConfirmation', true);
            }
        );
        state.signatureApi.get(UserInfoResolver.arrayBufferHeader(state.token)).then(response => {
            const as = Buffer.from(response.data, 'binary').toString('base64');
            commit('setSignatureUrl', as);
        });
        if (state.requestUser === jwtDecode(state.token).preferred_username) {
            state.avatarApi.get(UserInfoResolver.arrayBufferHeader(state.token)).then(response => {
                const as = Buffer.from(response.data, 'binary').toString('base64');
                commit('setAvatarUrl', as);
            });
        } else {
            state.avatarApi.getByUserId(state.requestUser, UserInfoResolver.arrayBufferHeader(state.token)).then(response => {
                const as = Buffer.from(response.data, 'binary').toString('base64');
                commit('setAvatarUrl', as);
            });
        }

        commit('setFirstChange', UserInfoResolver.checkForFirstInitOfUserData(state.profile));
    },
    async updateProfileData({commit}, profile) {
        await state.userApi.saveUserProfile(UserInfoResolver.mapToDTO(profile), UserInfoResolver.headerConfig(state.token)).catch(() => {
            commit('setProfile', UserInfoResolver.mapUser(profile));
        });
        if (state.firstChange) {
            window.parent.postMessage('init success', state.hostURL);
        }
        commit('setInitializationConfirmation', false);
        await state.userApi.getUserProfileFromUserId(state.requestUser, UserInfoResolver.headerConfig(state.token)).then(
            returnData => {
                commit('setProfile', UserInfoResolver.mapUser(returnData.data));
                commit('setUserPermissions', PermissionService.editorPermissions(state.profile, jwtDecode(state.token)));
                commit('setInitializationConfirmation', true);
            }
        );
        commit('setFirstChange', UserInfoResolver.checkForFirstInitOfUserData(state.profile));
    },
    /*eslint-disable no-unused-vars*/
    async saveSignature({commit}, signature) {
        await state.signatureApi.upload(signature, UserInfoResolver.headerConfig(state.token));
        await state.signatureApi.get(UserInfoResolver.arrayBufferHeader(state.token)).then(response => {
            const as = Buffer.from(response.data, 'binary').toString('base64');
            commit('setSignatureUrl', as);
        });
    },
    /*eslint-disable no-unused-vars*/
    async deleteSignature({commit}) {
        await state.signatureApi._delete(UserInfoResolver.headerConfig(state.token));
        commit('setSignatureUrl', '')
    },
    async deleteAddressFromUser({commit}, address) {
        commit('deleteAddress', address);
        await store.dispatch('updateProfileData', state.profile);
    },
    async updateAddresses({commit}, address) {
        commit('addAddress', address);
        await store.dispatch('updateProfileData', state.profile);
    },
    /*eslint-disable no-unused-vars*/
    async uploadProfilePicture({commit}, picture) {
        await state.avatarApi.upload(picture, UserInfoResolver.headerConfig(state.token));
        await store.dispatch('initApp');
    },
    /*eslint-disable no-unused-vars*/
    async deleteProfilePicture({commit}) {
        await state.avatarApi._delete(UserInfoResolver.headerConfig(state.token));
        await store.dispatch('initApp');
    },
    /*eslint-disable no-unused-vars*/
    async updatePassword({commit}, newPassword) {
        await state.passwordApi.changePassword({password: newPassword}, UserInfoResolver.headerConfig(state.token));
    }
};

export const getters = {
    getProfile: (state) => state.profile,
    getUserPermissions: (state) => state.userPermissions,
    getSignature: (state) => "data:image/png;base64," + state.signatureURL,
    getAvatar: (state) => "data:image/png;base64," + state.avatarURL,
    getFirstChange: (state) => state.firstChange
};

export default {
    state,
    mutations,
    actions,
    getters
};
