export default {
    editorPermissions(user, tokenInfo) {
        const roles = tokenInfo.realm_access.roles;
        return {
            birthdayPermission() {
                let permitted = false;
                if (user.birthdate === null || roles.includes('admin')) {
                    permitted = true;
                }
                return permitted;
            },
            isOwnUserProfile() {
                let permitted = false;
                permitted = (user.userId === tokenInfo.preferred_username);
                return permitted;
            },
            hiringDatePermission() {
                let permitted = false;
                if (user.hiringDate === null || roles.includes('admin')) {
                    permitted = true
                }
                return permitted;
            },
            adminPermission() {
                return roles.includes('admin');
            }
        }
    }
}
