<template>
  <div class="main-container">
    <h1 class="section-title">Konto</h1>
    <p class="section-title--small">Passwort ändern</p>
    <p class="disclaimer">Ihr Passwort sollte zwischen 8 und 32 Zeichen lang sein und dabei <br> mindestens ein Sonderzeichen, einen Großbuchstaben und eine Zahl enthalten.</p>
    <md-field class="form-field" type="password" :class="getValidationClass('password')">
      <label>Passwort</label>
      <md-input name="password" v-model="password" required  type="password"></md-input>
      <span class="md-error">Pflichtfeld</span>
    </md-field>
    <md-field class="form-field" :class="getValidationClass('repeatedPassword')">
      <label>Passwort wiederholen</label>
      <md-input name="repeat-password" v-model="repeatedPassword" required type="password"></md-input>
      <span class="md-error">Pflichtfeld</span>
    </md-field>
    <br>
    <md-button class="md-primary md-raised md-primary md-theme-default button" v-on:click="validateAndSave()">Einstellungen speichern</md-button>
  </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators';
import minLength from "vuelidate/src/validators/minLength";
import maxLength from "vuelidate/src/validators/maxLength";
import sameAs from "vuelidate/src/validators/sameAs";
import { mapActions } from "vuex";
import toast from "../../service/toaster-service";

export default {
  name: "UserSettings",
  data: () => {
    return {
      password: "",
      repeatedPassword: "",
      regEx: new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[@#&$%]).{8,32})')
    }
  },
  validations() {
    return {
        password: {
          required,
          minValue: minLength(8),
          maxValue: maxLength(32),
          regEx: (value) => this.regEx.test(value)
        },
        repeatedPassword: {
          required,
          minValue: 8,
          maxValue: 32,
          sameAsPassword: sameAs('password'),
          regEx: (value) => this.regEx.test(value)
        }
    }
  },
  methods: {
    ...mapActions(['updatePassword']),
    getValidationClass (fieldName) {
      const field = this.$v[fieldName];
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearValidation() {
      this.$v.$reset();
    },
    async validateAndSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.clearValidation();
        try {
          await this.updatePassword(this.password);
          this.$toast.success(toast.messages.password_update_success, toast.toastBaseOptions);
        } catch(e) {
          console.log(e);
          this.$toast.error(toast.messages.password_update_failed, toast.toastBaseOptions);
        }
      }
    }
  }
}
</script>

<style scoped>
  .disclaimer {
    color: darkgray;
  }
</style>
