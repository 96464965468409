<template>
    <div class="main-container">
        <div class="flex-organizer">
            <ProfileOverview class="profile-overview" />
            <ProfileSettings/>
        </div>
    </div>
</template>

<script>
import ProfileSettings from './views/ProfileSettings';
import ProfileOverview from './views/ProfileOverview';

export default {
    name: 'profile',
    components: {
        ProfileOverview,
        ProfileSettings
    }
}
</script>

<style scoped lang="scss">
    .main-container {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
    }

    .profile-overview {
        @media (max-width: $screen--medium) {
            width: 100%;
        }
    }

    .flex-organizer {
        display: flex;
        height: 100%;
        margin: 0;

        @media (max-width: $screen--medium) {
            display: block;
        }
    }
</style>
